import { ErrorType } from ".";

export enum ErrorsEnum {
  InvalidJWT = "INVALID_JWT",
  LinkExpired = "LINK_EXPIRED",
  GenericError = "GENERIC_ERROR",
  ApiError = "API_ERROR",
  BankAuthorisationError = "BANK_AUTHORISATION_ERROR",
  BankAuthConsentExpired = "BANK_AUTHORISATION_CONSENT_EXPIRED",
  PaymentRequestError = "PAYMENT_REQUEST_ERROR",
  InstitutionError = "INSTITUTION_ERROR",
  SessionExpired = "SESSION_EXPIRED",
  Forbidden = "FORBIDDEN_ERROR",
  ClientTimeout = "CLIENT_TIMEOUT",
  BankAccountNotEligibleForScheme = "BANK_ACCOUNT_NOT_ELIGIBLE_FOR_SCHEME",
  BankAccountMismatch = "BANK_ACCOUNT_MISMATCH",
  NoValidBankAccount = "NO_VALID_BANK_ACCOUNT",
  BankAuthorisationAlreadyCompleted = "BANK_AUTHORISATION_ALREADY_COMPLETED",
  PayerCountryNotSupported = "PAYER_COUNTRY_NOT_SUPPORTED",
  InstitutionLookupError = "INSTITUTION_LOOKUP_ERROR",
  BankIdVerificationUnsuccessful = "BANKID_VERIFICATION_UNSUCCESSFULL",
  BankIdAppNotFound = "BANKID_APP_NOT_FOUND",
  ResumeUnauthorisedError = "RESUME_UNAUTHORISED_ERROR",
}

export enum ErrorReasonsEnum {
  BankAuthorisationAlreadyCompleted = "bank_authorisation_already_completed",
  BankAccountNotEligibleForScheme = "bank_account_not_eligible_for_scheme",
  BankAccountMismatch = "bank_account_mismatch",
  NoValidBankAccount = "no_valid_bank_account",
  PayerCountryNotSupported = "payer_country_not_supported",
  InstitutionNotSupported = "institution_not_supported",
  SchemeNotSupportedByInstitution = "scheme_not_supported_by_institution",
  BankAuthorisationRejected = "NOAS",
}

export enum PlaidErrorsEnum {
  NoAccounts = "NO_ACCOUNTS",
  InvalidLinkToken = "INVALID_LINK_TOKEN",
  InvalidCredentials = "INVALID_CREDENTIALS",
  InsufficientCredentials = "INSUFFICIENT_CREDENTIALS",
  InvalidMFA = "INVALID_MFA",
  Other = "PLAID_OTHER",
  // Plaid provide a large number of different error codes
  // and we don't have logic specific to each one;
  // the 'Other' key represents these codes.

  // For the complete list of error codes, see: https://plaid.com/docs/errors/
}

export enum BankIdErrorsEnum {
  TimeLimitExceeded = "expiredTransaction",
  UserCancelled = "userCancel",
}

export enum BankFailureReasonsEnum {
  // PIS saltedge failure errors
  ExecutionTimeout = "ExecutionTimeout",
  ProviderError = "ProviderError",
  // PIS yappily failure errors
  InvalidGrant = "invalid_grant",
  ServerError = "server_error",
  TemporarilyUnavailable = "temporarily_unavailable",
  RequestNotSupported = "request_not_supported",
  RequestUriNotSupported = "request_uri_not_supported",
  AccessDenied = "access_denied",
  ConsentRejected = "consent_rejected",
  // PayTo denial from cuscal
  PayToDenied = "denied",
}

export const BankFailureErrorsToShowFallback = [
  BankFailureReasonsEnum.ExecutionTimeout,
  BankFailureReasonsEnum.ProviderError,
  BankFailureReasonsEnum.InvalidGrant,
  BankFailureReasonsEnum.ServerError,
  BankFailureReasonsEnum.TemporarilyUnavailable,
  BankFailureReasonsEnum.RequestNotSupported,
  BankFailureReasonsEnum.RequestUriNotSupported,
  BankFailureReasonsEnum.AccessDenied,
];

export type BankFailures =
  | ErrorReasonsEnum
  | BankIdErrorsEnum
  | BankFailureReasonsEnum
  | "Default";

export const BankFailureErrors: Record<BankFailures, ErrorType> = {
  [ErrorReasonsEnum.BankAuthorisationAlreadyCompleted]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation has already been completed",
  },
  [ErrorReasonsEnum.BankAccountNotEligibleForScheme]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank account is not eligible for this scheme",
  },
  [ErrorReasonsEnum.BankAccountMismatch]: {
    errorType: ErrorsEnum.BankAccountMismatch,
    errorMessage:
      "Bank account/s authorised do not match details entered on CollectBankAccount action",
  },
  [ErrorReasonsEnum.NoValidBankAccount]: {
    errorType: ErrorsEnum.NoValidBankAccount,
    errorMessage: "No valid bank accounts were returned from provider",
  },
  [ErrorReasonsEnum.PayerCountryNotSupported]: {
    errorType: ErrorsEnum.PayerCountryNotSupported,
    errorMessage: "Payer country is not supported",
  },
  [ErrorReasonsEnum.InstitutionNotSupported]: {
    errorType: ErrorsEnum.InstitutionError,
    errorMessage: "Institution not supported",
  },
  [ErrorReasonsEnum.SchemeNotSupportedByInstitution]: {
    errorType: ErrorsEnum.InstitutionError,
    errorMessage: "Institution not supported by scheme",
  },
  [ErrorReasonsEnum.BankAuthorisationRejected]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation has been rejected",
  },
  [BankIdErrorsEnum.TimeLimitExceeded]: {
    errorType: ErrorsEnum.SessionExpired,
    errorMessage: "Time limit exceeded",
  },
  [BankIdErrorsEnum.UserCancelled]: {
    errorType: ErrorsEnum.BankIdVerificationUnsuccessful,
    errorMessage: "User cancelled the action",
  },
  [BankFailureReasonsEnum.ExecutionTimeout]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation execution timed out",
  },
  [BankFailureReasonsEnum.AccessDenied]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation has been denied",
  },
  [BankFailureReasonsEnum.ConsentRejected]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation has been denied",
  },
  [BankFailureReasonsEnum.PayToDenied]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Bank authorisation has been denied",
  },
  [BankFailureReasonsEnum.ProviderError]: {
    errorType: ErrorsEnum.ApiError,
    errorMessage: "Provider error",
  },
  [BankFailureReasonsEnum.InvalidGrant]: {
    errorType: ErrorsEnum.InstitutionError,
    errorMessage: "Invalid grant",
  },
  [BankFailureReasonsEnum.ServerError]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Server error",
  },
  [BankFailureReasonsEnum.TemporarilyUnavailable]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Temporarily unavailable",
  },
  [BankFailureReasonsEnum.RequestNotSupported]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Requet not supported",
  },
  [BankFailureReasonsEnum.RequestUriNotSupported]: {
    errorType: ErrorsEnum.BankAuthorisationError,
    errorMessage: "Request URI not supported",
  },
  ["Default"]: {
    errorType: ErrorsEnum.GenericError,
    errorMessage: "An error was returned from the bankAuthorisation",
  },
};
