import {
  Currency,
  DetailsScheme,
  AvailableDebitSchemeEnum as Scheme,
  HomeSchemeEnum,
} from "@gocardless/api/dashboard/types";
export { AvailableDebitSchemeEnum as Scheme } from "@gocardless/api/dashboard/types";
export { HomeSchemeEnum } from "@gocardless/api/dashboard/types";
import { IconName } from "src/legacy-ui/icons";

import { CountryCodes } from "./country";

type AllScheme = Scheme | HomeSchemeEnum | DetailsScheme;

export const schemeToCurrency: Record<AllScheme, Currency> = {
  [Scheme.Ach]: Currency.Usd,
  [Scheme.Autogiro]: Currency.Sek,
  [Scheme.Bacs]: Currency.Gbp,
  [Scheme.Becs]: Currency.Aud,
  [Scheme.BecsNz]: Currency.Nzd,
  [Scheme.Betalingsservice]: Currency.Dkk,
  [Scheme.Pad]: Currency.Cad,
  [Scheme.SepaCore]: Currency.Eur,
  [HomeSchemeEnum.Sepa]: Currency.Eur,
  [DetailsScheme.SepaCor1]: Currency.Eur,
  [HomeSchemeEnum.FasterPayments]: Currency.Gbp,
  [HomeSchemeEnum.SepaCreditTransfer]: Currency.Eur,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: Currency.Eur,
  [HomeSchemeEnum.PayTo]: Currency.Aud,
};

export const schemeToCountryIconName: Record<AllScheme, IconName> = {
  [Scheme.Ach]: "FlagUS",
  [Scheme.Autogiro]: "FlagSE",
  [Scheme.Bacs]: "FlagUK",
  [Scheme.Becs]: "FlagAU",
  [Scheme.BecsNz]: "FlagNZ",
  [Scheme.Betalingsservice]: "FlagDK",
  [Scheme.Pad]: "FlagCA",
  [Scheme.SepaCore]: "FlagEU",
  [HomeSchemeEnum.Sepa]: "FlagEU",
  [DetailsScheme.SepaCor1]: "FlagEU",
  [HomeSchemeEnum.FasterPayments]: "FlagUK",
  [HomeSchemeEnum.SepaCreditTransfer]: "FlagEU",
  [HomeSchemeEnum.SepaInstantCreditTransfer]: "FlagEU",
  [HomeSchemeEnum.PayTo]: "FlagAU",
};

export const schemeToDisabledCountryIconName: Record<AllScheme, IconName> = {
  [Scheme.Ach]: "FlagUSDisabled",
  [Scheme.Autogiro]: "FlagSEDisabled",
  [Scheme.Bacs]: "FlagUKDisabled",
  [Scheme.Becs]: "FlagAUDisabled",
  [Scheme.BecsNz]: "FlagNZDisabled",
  [Scheme.Betalingsservice]: "FlagDKDisabled",
  [Scheme.Pad]: "FlagCADisabled",
  [Scheme.SepaCore]: "FlagEUDisabled",
  [HomeSchemeEnum.Sepa]: "FlagEUDisabled",
  [DetailsScheme.SepaCor1]: "FlagEUDisabled",
  [HomeSchemeEnum.FasterPayments]: "FlagUKDisabled",
  [HomeSchemeEnum.SepaCreditTransfer]: "FlagEUDisabled",
  [HomeSchemeEnum.SepaInstantCreditTransfer]: "FlagEUDisabled",
  [HomeSchemeEnum.PayTo]: "FlagAUDisabled",
};

// TODO: This case statement won't compile, as we don't have all the
// HomeSchemeEnum values available to map into Scheme. Once we do, we can
// uncomment it- but we don't use this right now, so perhaps it's unnecessary?
/*
 * export const sunSchemeToScheme: Record<HomeSchemeEnum, Scheme> = {
 *   [HomeSchemeEnum.Ach]: Scheme.Ach,
 *   [HomeSchemeEnum.Autogiro]: Scheme.Autogiro,
 *   [HomeSchemeEnum.Bacs]: Scheme.Bacs,
 *   [HomeSchemeEnum.Becs]: Scheme.Becs,
 *   [HomeSchemeEnum.BecsNz]: Scheme.BecsNz,
 *   [HomeSchemeEnum.Betalingsservice]: Scheme.Betalingsservice,
 *   [HomeSchemeEnum.Pad]: Scheme.Pad,
 *   [HomeSchemeEnum.Sepa]: Scheme.SepaCore,
 *   [HomeSchemeEnum.FasterPayments]: Scheme.FasterPayments, // TODO
 * };
 */

export const countryToScheme: Record<CountryCodes, Scheme> = {
  AD: Scheme.SepaCore,
  AT: Scheme.SepaCore,
  AU: Scheme.Becs,
  AX: Scheme.SepaCore,
  BE: Scheme.SepaCore,
  BG: Scheme.SepaCore,
  BL: Scheme.SepaCore,
  CA: Scheme.Pad,
  CH: Scheme.SepaCore,
  CY: Scheme.SepaCore,
  CZ: Scheme.SepaCore,
  DE: Scheme.SepaCore,
  DK: Scheme.Betalingsservice,
  EE: Scheme.SepaCore,
  ES: Scheme.SepaCore,
  FI: Scheme.SepaCore,
  FL: Scheme.SepaCore,
  FO: Scheme.Betalingsservice,
  FR: Scheme.SepaCore,
  GB: Scheme.Bacs,
  GG: Scheme.Bacs,
  GF: Scheme.SepaCore,
  GL: Scheme.Betalingsservice,
  GP: Scheme.SepaCore,
  GR: Scheme.SepaCore,
  HR: Scheme.SepaCore,
  HU: Scheme.SepaCore,
  IE: Scheme.SepaCore,
  IM: Scheme.Bacs,
  IS: Scheme.SepaCore,
  IT: Scheme.SepaCore,
  JE: Scheme.Bacs,
  LT: Scheme.SepaCore,
  LU: Scheme.SepaCore,
  LV: Scheme.SepaCore,
  MC: Scheme.SepaCore,
  MF: Scheme.SepaCore,
  MQ: Scheme.SepaCore,
  MT: Scheme.SepaCore,
  NL: Scheme.SepaCore,
  NZ: Scheme.BecsNz,
  NO: Scheme.SepaCore,
  PL: Scheme.SepaCore,
  PM: Scheme.SepaCore,
  PT: Scheme.SepaCore,
  RE: Scheme.SepaCore,
  RO: Scheme.SepaCore,
  SE: Scheme.Autogiro,
  SI: Scheme.SepaCore,
  SK: Scheme.SepaCore,
  SM: Scheme.SepaCore,
  US: Scheme.Ach,
  VA: Scheme.SepaCore,
  YT: Scheme.SepaCore,
};

export const InstantBankPaySchemes = [
  HomeSchemeEnum.FasterPayments,
  HomeSchemeEnum.SepaCreditTransfer,
  HomeSchemeEnum.SepaInstantCreditTransfer,
];

export const DirectDebitSchemes = [
  Scheme.Ach,
  Scheme.Autogiro,
  Scheme.Bacs,
  Scheme.Becs,
  Scheme.BecsNz,
  Scheme.Betalingsservice,
  Scheme.Pad,
  Scheme.SepaCore,
];

export const BankRecurringSchemes = [
  HomeSchemeEnum.FasterPayments,
  HomeSchemeEnum.PayTo,
];
